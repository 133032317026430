
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NewModal',
  emits: ['close-modal'],
  props: {
    customClass: {
      type: String,
      required: false,
      default: 'p-2 md:p-4 bg-lightSurface-50',
    },
  },
  setup(props, { emit }) {
    const closeModal = () => emit('close-modal');

    return {
      closeModal,
    };
  },
});
